<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 font-weight-bold flex-column" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        <div class="grey--text text--darken-3 text-uppercase">
          Consultar tablas BD
        </div>
        <!-- <div class="grey--text text--darken-2" :style="($vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;')">
          <b>Fecha actual</b>: {{ `${$dayjs().tz().format('DD [de] MMMM [del] YYYY')}` }}
        </div> -->
      </v-card-title>
    </v-card>

    <v-alert
      cols="12" sm="12" md="12" 
      elevation="0"
      class="mb-2"
      border="left"
      colored-border
      color="warning"
    >
      <div class="d-flex grey--text text--darken-2 align-center justify-space-between">
        <!-- <v-icon class="mr-4" color="primary">mdi-information</v-icon> -->
        <div class="pl-2">
          Si se va a eliminar un registro compruebe que se esté 100% seguro que no se afecarán otras partes. Los registros que se 
          pueden eliminar con seguridad son: abonos cancelados, planes y planes de cliente, las otras tablas requieren una comprobación
          mas a detalle para evitar borrar por accidente algún registro importante o que dependa de otra tabla.
        </div>
      </div>
    </v-alert>

<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->
    <v-card class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">

        <v-col cols="12" md="5" sm="5" xs="12"> 
          <v-select
            :label="`Consultar tabla...`"
            :items="tablaBD_select"
            :hint="``"
            persistent-hint
            v-model="tablaBD_selected"
          ></v-select>
        </v-col> 

      </v-row>
    </v-card>
<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->

    <DataTable
      id="saldos-table"
      :headers="headers"
      :title="`Tabla: ${tablaBD_selected}`"
      :subtitle="`${BDTable.items.length} registro(s)`"
      :items="BDTable.items"
      :loading="tableLoading"
      :searchEnabled="true"
      :showToolbar="true"
      :showEditarAction="false"
      :sortBy="['id']"
      :sortDesc="[false, true]"
      @deleteItem="regDelete"
    >
    </DataTable>

  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { APP_DEBUG_MODE as APP_DEBUG, TABLAS_BD } from "@/utils/Constants";
import { actions, getters, STORE_NAMESPACES } from "@/store";
import { ref, watch, computed, onBeforeUnmount } from "vue";

export default {
  components: {
    DataTable,
  },
  setup(props, ctx) {
    const { APP, DEV_ADMIN } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[Reporte_BD]: ReporteBD unmount => SET_DEFAULT()");

      actions[DEV_ADMIN].SET_DEFAULT();
    });

    const forma_pago_header = ref("Trimestre");
    const headers = computed(() => {
      // construir el header del datatable dependiendo de las propiedades de la tabla seleccionada
      const columns = [
        { text: "Id", value: "id" },
      ]

      const dynamic_columns = [];

      switch(tablaBD_selected.value) {
        case TABLAS_BD.ABONOS:
            columns.push(
              Col('IdPlanPago',     'id_plan_pagos'),
              Col('IdPlanCliente',  'id_plan_cliente'),
              Col('Client',         'nombre_completo_cliente'),
              Col('Cob',            'nombre_completo_cobrador'),
              Col('Cant',           'cantidad'),
              Col('FAb',            'fecha_abono_BD'),
              Col('Stat',           'abono_estatus'),
              Col('IdUsr',          'id_usuario_logueado'),
            )
          break;
        case TABLAS_BD.CLIENTES:
            columns.push(
              Col('IdCob',    'id_cobrador'),
              Col('NClient',  'num_cliente'),
              Col('Nombre',   'nombre'),
              Col('Apellido', 'apellidos'),
              Col('Stat',     'estatus'),
            )
          break;
        case TABLAS_BD.PLAN_PAGOS:
            columns.push(
              Col('IdPC',   'id_plan_cliente'),
              Col('Am',     'amortizacion'),
              Col('FPago',  'forma_pago'),
              Col('Moneda', 'pago_moneda_contratada'),
              Col('Cambio', 'tipo_cambio'),
              Col('PPesos', 'pago_pesos'),
              Col('SaldoP', 'saldo_pendiente'),
              Col('PagoPer',  'pago_periodico_calculado'),
              Col('Abon',     'abonado'),
              Col('Saldo',    'saldo'),
            )
          break;
        case TABLAS_BD.PLANES:
            columns.push(
              Col('Nombre', 'nombre'),
              Col('Min',    'min_anios'),
              Col('Max',    'max_anios'),
              Col('Stat',   'estatus'),
            )
          break;
        case TABLAS_BD.PLANES_CLIENTES:
            columns.push(
              Col('IdCli',  'id_cliente'),
              Col('Pol',    'poliza'),
              Col('Mon',    'moneda'),
              Col('FPago',  'forma_pago'),
              Col('AboPag', 'abonos_pagados_migracion'),
              Col('NumPT',  'num_pagos_al_trimestre'),
              Col('FechProx', 'fecha_prox_pago'),
              Col('Stat',     'estatus'),
            )
          break;
        case TABLAS_BD.USER_LOGS:
            columns.push(
              Col('IdLogRel', 'id_log_relacionado'),
              Col('TipoL',    'tipo_log'),
              Col('FechaL',   'fecha_log'),
              Col('Nombre',   'nombre'),
              Col('Apellido', 'apellidos'),
              Col('Priv',     'privilegio'),
            )
          break;
        case TABLAS_BD.USERS:
            columns.push(
              Col('Nombre',   'nombre'),
              Col('Apellido', 'apellidos'),
              Col('FechaR',   'fecha_reg'),
              Col('Priv',     'privilegio'),
              Col('Stat',     'estatus'),
            )
          break;
      }

      columns.push(Col('Act', 'action'));

      return columns;
    });

    const tableLoading = getters[APP].getLoading;
    const BDTable = useDataTable(getters[DEV_ADMIN].getAll, {});

    const tablaBD_selected = ref("");
    const tablaBD_select = [
      { text: `Abonos`,          value: TABLAS_BD.ABONOS },
      { text: `Clientes`,        value: TABLAS_BD.CLIENTES },
      { text: `Plan Pagos`,      value: TABLAS_BD.PLAN_PAGOS },
      { text: `Planes`,          value: TABLAS_BD.PLANES },
      { text: `Planes Clientes`, value: TABLAS_BD.PLANES_CLIENTES },
      { text: `User Logs`,       value: TABLAS_BD.USER_LOGS },
      { text: `Users`,           value: TABLAS_BD.USERS },
    ];

    watch(() => tablaBD_selected.value, (nombre_tabla) => {
      APP_DEBUG_MODE && console.log("fetch: ", nombre_tabla);
      actions[DEV_ADMIN].FETCH_TABLE(nombre_tabla);
    });

    const Col = (text, value) => ({ text, value });

    const regDelete = (tableRowData) => {
      APP_DEBUG_MODE && console.log("Fila de datos a borrar: ", tableRowData);
      APP_DEBUG_MODE && console.log("De la tabla: ", tablaBD_selected.value);
      actions[DEV_ADMIN].DELETE(tablaBD_selected.value, tableRowData);
    }
    
    return {
      headers,

      tablaBD_selected,
      tablaBD_select,
      BDTable,
      regDelete,
      
      tableLoading,
    };
  }
};
</script>